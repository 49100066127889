import { MedusaProvider } from "medusa-react";
import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LayeredModalProvider } from "./components/molecules/modal/layered-modal";
import { SteppedProvider } from "./components/molecules/modal/stepped-modal";
import { FeatureFlagProvider } from "./context/feature-flag";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { medusaUrl } from "./services/config";
import queryClient from "./services/queryClient";

const Page = ({ children }: PropsWithChildren) => {
  return (
    <MedusaProvider
      baseUrl={medusaUrl}
      queryClientProviderProps={{
        client: queryClient,
      }}
    >
      <FeatureFlagProvider>
        <SteppedProvider>
          <LayeredModalProvider>{children}</LayeredModalProvider>
        </SteppedProvider>
      </FeatureFlagProvider>
    </MedusaProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Page>
      <App />
    </Page>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
